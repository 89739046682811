var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product-pitch-toggle" }, [
    _c(
      "div",
      {
        staticClass:
          "w-100 d-flex flex-row align-items-center justify-content-between",
      },
      [
        _c(
          "div",
          { staticClass: "d-flex flex-row" },
          [
            _c(
              "b-checkbox",
              {
                attrs: {
                  switch: "",
                  disabled:
                    _vm.addingProductToCart || _vm.removingProductFromCart,
                },
                on: { change: _vm.updateChecked },
                model: {
                  value: _vm.checked,
                  callback: function ($$v) {
                    _vm.checked = $$v
                  },
                  expression: "checked",
                },
              },
              [
                _c("div", [
                  _vm._v(
                    "\n          " + _vm._s(_vm.toggleLabel()) + "\n        "
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _vm.suggestion.description
              ? _c("fa-icon", {
                  staticClass: "question-circle pr-3 help-text",
                  attrs: { icon: "question-circle", size: "1x" },
                  on: {
                    mouseenter: function ($event) {
                      _vm.showPopover = true
                    },
                    mouseleave: function ($event) {
                      _vm.showPopover = false
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
    _vm.showPopover
      ? _c("div", { staticClass: "popover" }, [
          _c("div", { staticClass: "arrow" }),
          _c("div", { staticClass: "popover-body p-3" }, [
            _c("h6", { staticClass: "mt-0" }, [
              _vm._v("\n        How We Work For You\n      "),
            ]),
            _vm.isFilingProduct
              ? _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.suggestion.description) +
                      "\n      "
                  ),
                ])
              : _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.suggestion.description) + " "
                  ),
                  _c("strong", [_vm._v("for " + _vm._s(_vm.priceAndDuration))]),
                ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }