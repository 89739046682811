export function formatUSAtoUS(country) {
  return [null, false, '', 'USA', 'United States'].includes(country) ? 'US' : country
}

// TODO Would be nice to have a comment on this method since it's not very clear what
// it's doing or why
export function getFieldSuggestionValue(field) {
  let fieldValue = ''
  field.data.parts.forEach(fp => {
    const fieldPart = fp.replace('address.', '')
    const name = fieldPart === 'attention' ? 'name' : fieldPart
    const newVal = field.meta.suggestion[name]

    if (name in field.meta.suggestion) {
      fieldValue +=
        name === 'country' ? formatUSAtoUS(field.meta.suggestion[name]) : (newVal || '')
    }
  })
  return fieldValue
}
